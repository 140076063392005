import { Box, Tooltip, Icon, Button } from "@chakra-ui/react";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import { useUser } from "@auth0/nextjs-auth0";
import { FaRegArrowAltCircleUp, FaHeart } from "react-icons/fa";
import { User_Type_Enum } from "generated/graphql";

interface ProPillProps {}

const ProPill = (props: ProPillProps) => {
  const { user, isLoading, error } = useUser();
  const { t } = useTranslation("common");
  if (isLoading || error || !user) {
    return <></>;
  }

  return (
    <Box d="inline-block">
      {(user?.user_type as User_Type_Enum) === User_Type_Enum.Paidgrowth ||
      (user?.user_type as User_Type_Enum) === User_Type_Enum.Paidpremium ||
      (user?.user_type as User_Type_Enum) === User_Type_Enum.Coach ? (
        <Tooltip
          hasArrow
          label="Thanks for being a subscriber &lt;3"
          aria-label="Subscribers"
        >
          <div>
            <NextLink href="/profile" passHref>
              <Button color="brand" variant="ghost" role="link">
                <Icon as={FaHeart} color="brand" />
              </Button>
            </NextLink>
          </div>
        </Tooltip>
      ) : (
        <NextLink href="/pricing" passHref>
          <Button color="brand" variant="ghost" role="link">
            <Icon as={FaRegArrowAltCircleUp} color="brand" mr={1} />
            {t("navbar.upgrade")}
          </Button>
        </NextLink>
      )}
    </Box>
  );
};

export default ProPill;
