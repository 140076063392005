import { Wrapper, Box, Inner } from "./styled";

interface BurgerProps {
  active?: boolean;
}

export const Burger = ({ active }: BurgerProps) => {
  return (
    <Wrapper active={active}>
      <Box>
        <Inner active={active} />
      </Box>
    </Wrapper>
  );
};
