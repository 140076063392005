import { Link, Box, Icon, useMediaQuery } from "@chakra-ui/react";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import LanguagePicker from "./LanguagePicker";
import { useUser } from "@auth0/nextjs-auth0";
import { FaExternalLinkAlt } from "react-icons/fa";

interface MenuItems {
  text: string | JSX.Element;
  href: string;
  condition: boolean;
  isExternal?: boolean;
}

interface MenuContents {
  title: string;
  titleLink: string | null;
  condition: boolean;
  items: MenuItems[];
}

export const Menu = () => {
  const { user } = useUser();
  const { t } = useTranslation("common");
  const [isSmall] = useMediaQuery("(max-width: 40em)");

  const menuContents: MenuContents[] = [
    {
      title: t("menu.titles.home"),
      titleLink: "/",
      condition: true,
      items: [
        {
          text: t("menu.items.coachAvailability"),
          href: "/profile/availability",
          condition: user?.user_type === "coach",
        },
        {
          text: t("menu.items.booking"),
          href: "/booking",
          condition: true,
        },
        {
          text: t("menu.items.categories"),
          href: "/category",
          condition: true,
        },
        {
          text: t("menu.items.pricing"),
          href: "/pricing",
          condition: !user,
        },
        // message a coach
      ],
    },
    {
      title: t("menu.titles.yourAccount"),
      titleLink: null,
      condition: !!user,
      items: [
        {
          text: t("menu.items.profile"),
          href: "/profile",
          condition: true,
        },
        {
          text: t("menu.items.bookings"),
          href: "/profile/bookings",
          condition: true,
        },
        {
          text: t("menu.items.recommendations"),
          href: "/profile/recommendations",
          condition: true,
        },
        {
          text:
            user && (user.user_type as string).startsWith("paid")
              ? t("menu.items.subscription")
              : t("menu.items.upgrade"),
          href: "/pricing",
          condition: true,
        },
      ],
    },
    {
      title: t("menu.titles.aboutUs"),
      titleLink: null,
      condition: true,
      items: [
        {
          text: t("menu.items.coaches"),
          href: "/coaches",
          condition: true,
        },
        {
          text: t("menu.items.method"),
          href: "/method",
          condition: false, //@TODO: add method page
        },
        {
          text: t("menu.items.team"),
          href: "/our-story",
          condition: true,
        },
      ],
    },
    {
      title: t("menu.titles.legal"),
      titleLink: null,
      condition: true,
      items: [
        {
          text: t("menu.items.impressum"),
          href: "/impressum",
          condition: true,
        },
        {
          text: t("menu.items.dataProtection"),
          href: "/impressum",
          condition: true,
        },
        {
          text: t("menu.items.termsAndConditions"),
          href: "/impressum",
          condition: true,
        },
      ],
    },
    {
      title: t("menu.titles.social"),
      titleLink: null,
      condition: true,
      items: [
        {
          text: (
            <>
              {t("menu.items.facebook")}{" "}
              <Icon as={FaExternalLinkAlt} mx="2px" boxSize={3} />
            </>
          ),
          href: "https://www.facebook.com/hellodearest.io",
          condition: true,
          isExternal: true,
        },
        {
          text: (
            <>
              {t("menu.items.instagram")}{" "}
              <Icon as={FaExternalLinkAlt} mx="2px" boxSize={3} />
            </>
          ),
          href: "https://www.instagram.com/dearestapp/",
          condition: true,
          isExternal: true,
        },
      ],
    },
  ];

  return (
    <>
      <Box
        d="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        p={["2em 2.4em", "2em 2.4em", "2em 4em", "2em 6em"]}
      >
        {menuContents
          .filter((menuSection) => menuSection.condition)
          .map((menuSection, index) => (
            <Box
              key={`menusection-${menuSection.title}`}
              mb={2}
              minHeight="100px"
              width={["100%", "auto"]}
              minWidth={["100%", "50%", "30%", "auto"]}
              mt={[4, 4, 4, 0]}
            >
              <h4>{String(index + 1).padStart(2, "0")}</h4>
              <h3>
                {menuSection.titleLink === null ? (
                  <>{menuSection.title}</>
                ) : (
                  <NextLink href={menuSection.titleLink}>
                    {menuSection.title}
                  </NextLink>
                )}
              </h3>
              {menuSection.items
                .filter((item) => item.condition)
                .map((item, itemIndex) => (
                  <NextLink
                    key={`menusection-${index}-link-${item.href}-${itemIndex}`}
                    href={item.href}
                    passHref
                  >
                    <Link variant="menu" isExternal={item.isExternal}>
                      {item.text}
                    </Link>
                  </NextLink>
                ))}
            </Box>
          ))}
        {isSmall && user && (
          <Link href="/api/auth/logout">
            <Link variant="menu">{t("navbar.logout")}</Link>
          </Link>
        )}
      </Box>
      <Box float={["none", "right"]} pr="2.5em" pl="2em" mb={["140px", "20px"]}>
        <LanguagePicker />
      </Box>
    </>
  );
};
