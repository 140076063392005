import { Box, Center, Text, Flex, Link, Image, Icon } from "@chakra-ui/react";
import LanguagePicker from "components/Menu/LanguagePicker";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

interface FooterProps {}

const Footer = (props: FooterProps) => {
  const { t } = useTranslation("common");
  return (
    <Box margin="0" pt={16} display="flex">
      <Box marginTop="auto" bg="black" color="white" flex={1}>
        <Flex wrap="wrap" mt={8}>
          <Box flexGrow={1} textAlign={["left", "center"]}>
            <Box mx={["1em", "auto"]} display="inline-block" textAlign="left">
              <NextLink href="/" passHref>
                <Link height={30}>
                  <Image
                    src="/dearest_logo_white.svg"
                    height={[30, "40px"]}
                    width={[150, "200px"]}
                    alt="Dearest"
                    className="dearestLogo"
                    mt="10px"
                    fallback={<></>}
                  />
                </Link>
              </NextLink>
              <Box mt={6}>
                <LanguagePicker footer />
              </Box>
            </Box>
          </Box>
          <Flex
            wrap="wrap"
            justifyContent="space-around"
            flexGrow={3}
            width={["100%", "auto"]}
            px="1em"
          >
            <Box mb={2} width={["100%", "auto"]} mt={[2, 0]}>
              <NextLink href="/" passHref>
                <Link variant="menu">{t("menu.titles.home")}</Link>
              </NextLink>
              <NextLink href="/pricing" passHref>
                <Link variant="menu">{t("menu.items.pricing")}</Link>
              </NextLink>
            </Box>
            <Box mb={2} width={["100%", "auto"]} mt={[2, 0]}>
              <NextLink href="/coaches" passHref>
                <Link variant="menu">{t("menu.items.coaches")}</Link>
              </NextLink>

              <NextLink href="/our-story" passHref>
                <Link variant="menu">{t("menu.items.team")}</Link>
              </NextLink>
            </Box>
            <Box mb={2} width={["100%", "auto"]} mt={[2, 0]}>
              <NextLink href="/impressum" passHref>
                <Link variant="menu">{t("menu.items.impressum")}</Link>
              </NextLink>

              <NextLink href="/impressum" passHref>
                <Link variant="menu">{t("menu.items.dataProtection")}</Link>
              </NextLink>
              <NextLink href="/impressum" passHref>
                <Link variant="menu">{t("menu.items.termsAndConditions")}</Link>
              </NextLink>
            </Box>
            <Box mb={2} width={["100%", "auto"]} mt={[2, 0]}>
              <NextLink
                href="https://www.facebook.com/hellodearest.io"
                passHref
              >
                <Link variant="menu" isExternal>
                  <Icon as={FaFacebookF} boxSize={6} />
                </Link>
              </NextLink>
              <NextLink href="https://www.instagram.com/dearestapp/" passHref>
                <Link variant="menu" isExternal>
                  <Icon as={FaInstagram} boxSize={6} />
                </Link>
              </NextLink>
            </Box>
          </Flex>
        </Flex>
        <Box py="20px" mt={6} mb={6}>
          <Center>
            <Text>{`© ${new Date().getFullYear()} Dearest GmbH. All rights reserved`}</Text>
          </Center>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
