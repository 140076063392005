import { ReactNode } from "react";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import { NavBar } from "../NavBar";
import { Wrapper, WrapperVariant } from "components/Wrapper";
import Footer from "components/Footer";
import SmallChat from "utils/SmallChat";
import { BoxProps, Flex } from "@chakra-ui/react";
import { AlternativeHref } from "components/AlternativeHref";
interface LayoutProps extends BoxProps {
  variant?: WrapperVariant;
  children?: ReactNode;
  hideChat?: boolean;
  standalone?: boolean;
}

export const Layout = ({
  children,
  variant,
  standalone,
  hideChat,
  ...props
}: LayoutProps) => {
  const { t } = useTranslation("common");
  if (standalone) {
    return <Wrapper variant={variant}>{children}</Wrapper>;
  }
  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta
          key="application-name"
          name="application-name"
          content="Dearest"
        />
        <meta
          key="apple-mobile-web-app-capable"
          name="apple-mobile-web-app-capable"
          content="yes"
        />
        <meta
          key="apple-mobile-web-app-status-bar-style"
          name="apple-mobile-web-app-status-bar-style"
          content="default"
        />
        <meta
          key="apple-mobile-web-app-title"
          name="apple-mobile-web-app-title"
          content="Dearest"
        />
        <meta
          key="format-detection"
          name="format-detection"
          content="telephone=no"
        />
        <meta
          key="mobile-web-app-capable"
          name="mobile-web-app-capable"
          content="yes"
        />
        <meta
          key="msapplication-TileColor"
          name="msapplication-TileColor"
          content="#fa8c44"
        />
        <meta
          key="msapplication-tap-highlight"
          name="msapplication-tap-highlight"
          content="no"
        />
        <meta key="theme-color" name="theme-color" content="#ffffff" />

        <link
          key="apple-touch-icon"
          rel="apple-touch-icon"
          href="/apple-touch-icon.png"
        />
        <link
          key="apple-touch-icon152"
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-touch-icon.png"
        />
        <link
          key="apple-touch-icon180"
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          key="apple-touch-icon167"
          rel="apple-touch-icon"
          sizes="167x167"
          href="/apple-touch-icon.png"
        />

        <link
          key="icon"
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          key="icon"
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link key="manifest" rel="manifest" href="/manifest.json" />
        <link key="shortcut icon" rel="shortcut icon" href="/favicon.ico" />
        <link
          key="mask-icon "
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#fa8c44"
        />

        <title key="title">{t("title")}</title>
        <meta key="description" name="description" content={t("description")} />
        <meta key="robots" name="robots" content="index, follow" />

        {/* Facebook Meta Tags */}
        <meta
          key="og:url"
          property="og:url"
          content="https://app.hellodearest.io"
        />
        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:title" property="og:title" content={t("title")} />
        <meta
          key="og:description"
          property="og:description"
          content={t("description")}
        />
        <meta property="og:site_name" content="Dearest" />
        <meta
          key="og:image"
          property="og:image"
          content="https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/illustration_solo.png"
        />

        {/* Twitter Meta Tags */}
        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />
        <meta key="twitter:title" name="twitter:title" content={t("title")} />
        <meta
          key="twitter:description"
          name="twitter:description"
          content={t("description")}
        />
        <meta
          key="twitter:domain"
          property="twitter:domain"
          content="hellodearest.io"
        />
        <meta
          key="twitter:url"
          property="twitter:url"
          content="https://app.hellodearest.io"
        />
        {/* @TODO: Twitter account! */}
        {/*<meta key="twitter:creator" name='twitter:creator' content='@domstarkey' />*/}
        <meta
          key="twitter:image"
          name="twitter:image"
          content={
            "https://dearest-content-storage.fra1.cdn.digitaloceanspaces.com/illustration_solo.png"
          }
        />
        <meta name="apple-itunes-app" content="app-id=1583462259" />
        {/* FBPixel */}
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '238181484632370');
            fbq('track', 'PageView');
            `,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=238181484632370&ev=PageView&noscript=1"
            />`,
          }}
        />
        {/* FBPixel */}
      </Head>
      <AlternativeHref />
      <Flex minHeight="100vh" flexDir="column" justifyContent="flex-start">
        <NavBar />
        <Wrapper variant={variant} {...props}>
          {children}
        </Wrapper>
        <Footer />
        <SmallChat hide={standalone || hideChat} />
      </Flex>
    </>
  );
};
