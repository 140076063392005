import styled from "@emotion/styled";

export const Wrapper = styled.div<{ active?: boolean }>`
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
`;

export const Box = styled.div`
  width: 24px;
  height: 12px;
  display: inline-block;
  position: relative;
`;

export const Inner = styled.div<{ active?: boolean }>`
  display: block;
  top: 0px;
  height: 2px;
  transition: background-color 0s 0.13s linear;
  margin-top: -2px;
  background-color: #000;
  &::before,
  &::after {
    width: 24px;
    height: 2px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    content: "";
    display: block;
  }

  &::before {
    top: 6px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &::after {
    top: 14px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  ${({ active }) =>
    active &&
    `
    transition-delay: 0.18s;
    background-color: rgba(0,0,0,0) !important;
    &::before {
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.32s linear;
        transform: translate3d(0, 6px, 0) rotate(45deg);
      }

      &::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.32s linear;
        transform: translate3d(0, 6px, 0) rotate(-45deg); 
      }
  `}
`;
