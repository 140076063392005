import { CSSProperties } from "react";

interface OverlayProps {
  styles?: CSSProperties;
  onClick?: () => void;
  zIndex?: number;
}

export const Overlay = ({ styles, onClick, zIndex = 1 }: OverlayProps) => {
  return (
    <div
      onClick={onClick}
      style={{
        background: "rgba(0,0,0,0.4)",
        position: "fixed",
        left: "0",
        top: "0",
        width: "100vw",
        height: "100vh",
        zIndex,
        ...styles
      }}
    ></div>
  );
};
