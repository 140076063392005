import Head from "next/head";
import { useRouter } from "next/router";

export const AlternativeHref = () => {
  const { locale, defaultLocale, locales, asPath } = useRouter();
  if (asPath.startsWith("/category/") || asPath.startsWith("/article/")) {
    return <></>;
  }

  const altLocales = locales?.filter((loc) => loc !== locale);

  return (
    <Head>
      {altLocales?.map((altLocale) => {
        return (
          <link
            key={altLocale}
            rel="alternate"
            hrefLang={altLocale}
            href={`https://app.hellodearest.io${
              altLocale !== defaultLocale ? `/${altLocale}` : ""
            }${asPath}`}
          />
        );
      })}
    </Head>
  );
};
