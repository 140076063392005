import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

export type WrapperVariant =
  | "wrapper"
  | "wrapperFullHeight"
  | "wrapperNoTop"
  | "wrapperNoMargins"
  | "wrapperWide"
  | "wrapperWideNoMargins"
  | "quoteLeft"
  | "quoteRight"
  | "quoteVeryRight";

interface WrapperProps extends BoxProps {
  variant?: WrapperVariant;
  children?: React.ReactNode;
}

export const Wrapper = ({
  children,
  variant = "wrapper",
  ...rest
}: WrapperProps) => {
  const styles = useStyleConfig("Wrapper", { variant });
  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
};
