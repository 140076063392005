import NextLink from "next/link";
import { Link, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { trackEvent } from "utils/tracking";
import cookie from "cookie-cutter";

const LanguageLink = ({
  text,
  locale,
  href,
  current,
  footer,
}: {
  footer?: boolean;
  text: string;
  locale: string;
  href: string;
  current?: string;
}) => {
  return locale === current ? (
    <Text
      as="span"
      fontWeight={footer ? "normal" : "bold"}
      textDecoration={footer ? "underline" : "none"}
      textDecorationThickness="3px"
      textDecorationColor="purple.500"
    >
      {text}
    </Text>
  ) : (
    <NextLink href={href} locale={locale} passHref>
      <Link
        onClick={() => {
          const oneYearFromNow = new Date();
          oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
          cookie.set("NEXT_LOCALE", locale, { expires: oneYearFromNow });
          trackEvent("Changed Language", { from: current, to: locale });
        }}
      >
        {text}
      </Link>
    </NextLink>
  );
};

const LanguagePicker = ({ footer }: { footer?: boolean }) => {
  const { asPath, locale } = useRouter();
  const languages = [
    { locale: "de", name: "Deutsch" },
    { locale: "en", name: "English" },
  ];
  return (
    <>
      {languages.map((language, index) => (
        <Fragment key={language.locale}>
          <LanguageLink
            footer={footer}
            text={language.name}
            href={asPath}
            locale={language.locale}
            current={locale}
          />
          {index < languages.length - 1 && <> | </>}
        </Fragment>
      ))}
    </>
  );
};
export default LanguagePicker;
