import { Box, Flex, Link, Image, Button } from "@chakra-ui/react";
import NextLink from "next/link";
import { useUser } from "@auth0/nextjs-auth0";
import { useTranslation } from "next-i18next";
import { Slide, Fade, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { Overlay } from "components/Overlay";
import { Burger } from "components/Burger";
import { Menu } from "components/Menu";
import ProPill from "components/ProPill";
import { useEffect } from "react";

export const NavBar = () => {
  const [isSmall] = useMediaQuery("(max-width: 40em)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation("common");
  const { user, isLoading } = useUser();
  const { asPath } = useRouter();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [isOpen]);

  return (
    <>
      <Flex
        m={0}
        zIndex={200}
        position="sticky"
        top={0}
        bg="white"
        p={["0 0 0 12px", "0 12px"]}
        borderBottom={isOpen ? 0 : 1}
        borderStyle="solid"
        borderColor="gray.300"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box height={58} display="flex" alignItems="center" bg="white">
          <div>
            <Link onClick={isOpen ? onClose : onOpen}>
              <Box aria-label="Menu" w={6} h={6} mr={[4, 5]} ml={[2, 5]}>
                <Burger active={isOpen} />
              </Box>
            </Link>
          </div>
          <NextLink href="/" passHref>
            <Link height={30}>
              <Image
                src="/dearest_logo.svg"
                height={30}
                width={150}
                alt="Dearest"
                className="dearestLogo"
                fallback={<></>}
              />
            </Link>
          </NextLink>
        </Box>
        <Box bg="white" mr={[0, 5]}>
          <ProPill />
          {!user && !isLoading && (
            <Box d="inline-block">
              <Link href={`https://app.hellodearest.io/api/auth/login?returnTo=${asPath}&screen_hint=signup`}>
                <Button
                  color="black"
                  variant="ghost"
                  fontWeight="normal"
                  role="link"
                >
                  {t("navbar.login")}
                </Button>
              </Link>
            </Box>
          )}
          {user && !isLoading && !isSmall && (
            <Box d="inline-block" ml={5}>
              <Link href="/api/auth/logout">
                <Button
                  color="black"
                  variant="ghost"
                  fontWeight="normal"
                  role="link"
                >
                  {t("navbar.logout")}
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </Flex>
      <Slide direction="top" in={isOpen} style={{ zIndex: 100 }}>
        <Box
          p={["58px 0 0", "98px 0 0"]}
          color="black"
          bg="white"
          maxHeight="100vh"
          overflowY="scroll"
        >
          <Menu />
        </Box>
      </Slide>
      {isOpen && (
        <Fade in={isOpen}>
          <Overlay zIndex={99} onClick={onClose} />
        </Fade>
      )}
    </>
  );
};
