import { useUser } from "@auth0/nextjs-auth0";
import { useCallback, useEffect, useState } from "react";
import cookie from "cookie-cutter";
import { Text, useMediaQuery } from "@chakra-ui/react";
import CookieConsent from "react-cookie-consent";
import { isServer } from "./isServer";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Script from "next/script";

const SmallChat = ({ hide }: { hide?: boolean }) => {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const user = useUser();
  const [mounted, setMounted] = useState(false);
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [isSmall] = useMediaQuery("(max-width: 40em)");
  useEffect(() => {
    const chatCallback = () => {
      if ((window as any).Smallchat) {
        (window as any).Smallchat.set("name", user?.user?.given_name);
        (window as any).Smallchat.set("email", user?.user?.email);
      }
    };
    if (user.user) {
      window.addEventListener("smallchat:ready", chatCallback);
    }
    return () => {
      window.removeEventListener("smallchat:ready", chatCallback);
    };
  }, [user.user]);

  const loadSmallChat = useCallback(() => {
    if (document.getElementById("smallchatscript")) {
      return;
    }
    if (!mounted) {
      setMounted(true);
      window.Smallchat = {
        config: {
          slackTeamId: "T01BSJF685S",
          scChannelId: "-MWQ2WA7IlejzMu-udry",
          slackChannelId: "C01RVKJJMRC",
          uid: "-MWQ2Rn4EyqMHxWJGmyS",
          planId: null,
          accountCreated: 1616435334450,
        },
        appearance: {
          brand_color: "#fffaf0",
          contact_dismissible: true,
          contact_enabled: true,
          contact_field_label: "Email",
          contact_immediately: false,
          contact_prompt:
            locale === "de"
              ? "Hinterlasse uns gerne deine E-Mail-Adresse, um sicherzustellen, dass du unsere Antwort siehst:"
              : "Please leave us your email address to make sure you see our reply:",
          contact_reply:
            locale === "de"
              ? "Danke {{name}}! Du bekommst hier eine Antwort oder wir kontaktieren dich unter {{contact}}."
              : "Thanks {{name}}! You will get an answer here or we will contact you at {{contact}}.",
          custom_css:
            "#Smallchat .Launcher-button { border: 1px solid #F6AD55;}",
          hide_logo: true,
          hide_team_icon: false,
          launcher_pos: "right",
          launcher_prompt:
            locale === "de" ? "Chatte mit uns!" : "Chat with us!",
          launcher_type: isSmall ? "icon" : "button",
          messenger_blank: "",
          messenger_entry:
            locale === "de" ? "Was beschäftigt dich?" : "What's on your mind?",
          messenger_prompt:
            locale === "de" ? "Chatte mit uns!" : "Chat with us!",
          name_field_label: "Name",
          offline_greeting:
            locale === "de"
              ? "Danke für deine Nachricht, die wir vertraulich behandeln. Leider sind unsere Coaches gerade offline, wenn du uns deine E-Mail-Adresse hinterlässt, melden wir uns sobald wir wieder online sind. \n\nIn Notfällen oder bei Bedarf wende dich an deinen behandelnden Arzt oder Psychotherapeuten oder an die Telefonseelsorge. Die Telefonseelsorge kannst du rund um die Uhr unter den gebührenfreien Telefonnummern 0800 111 0 111 oder 0800 111 0 222 erreichen. Hier erhältst du kompetente, vertrauliche und anonyme Hilfe. Weitere Hilfe erhältst du auch unter den bekannten Notrufnummern Polizei: 110 und Feuerwehr: 112."
              : "Thank you for your message, which we will treat confidentially. Unfortunately, our coaches are currently offline, if you leave us your email address, we will contact you as soon as we are online again.  n  nIn emergencies or if necessary, contact your doctor or psychotherapist or the telephone counseling service. You can reach the telephone counseling around the clock on the toll-free telephone numbers 0800 111 0 111 or 0800 111 0 222. Here you get competent, confidential and anonymous help. You can also get further help under the well-known emergency numbers police: 110 and fire brigade: 112.",
          text_color: "#fa8c44",
        },
        behavior: {
          avatar_config: 1,
          friday: { from: "9:00", to: "22:00" },
          hide_offline: false,
          monday: { from: "9:00", to: "22:00" },
          operating_hours: true,
          saturday: { disabled: true, from: "9:00", to: "22:00" },
          sunday: { disabled: true, from: "9:00", to: "22:00" },
          thursday: { from: "9:00", to: "22:00" },
          timezone: "Europe/Berlin",
          tuesday: { from: "9:00", to: "22:00" },
          wednesday: { from: "9:00", to: "22:00" },
        },
      };
      var styles = document.createElement("link");
      styles.rel = "stylesheet";
      styles.href = "https://static.small.chat/messenger.css";
      document.head.appendChild(styles);
      setShow(true);
    }
  }, [isSmall, locale, mounted]);

  useEffect(() => {
    if (!isServer()) {
      if (cookie.get("CookieConsent") === "true" || showChat) {
        loadSmallChat();
      }
    }
  }, [loadSmallChat, showChat]);

  return (
    <>
      {show && (
        <Script
          id="smallchatscript"
          src="https://static.small.chat/messenger.js"
          strategy="lazyOnload"
          nonce={`nonce-${window.__nonce}`}
        />
      )}
      <CookieConsent
        style={{ background: "#f4efe6", color: "#1d1d1d" }}
        buttonStyle={{ background: "#fa8c44", color: "#ffffff" }}
        buttonText={t("cookieButton")}
        onAccept={() => {
          setShowChat(true);
        }}
      >
        <Text fontSize="sm">{t("cookieText")}</Text>
      </CookieConsent>
      {hide && (
        <style
          dangerouslySetInnerHTML={{
            __html: `#Smallchat {
            display: none;
          }
          `,
          }}
        />
      )}
    </>
  );
};

export default SmallChat;
